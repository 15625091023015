// GitHub Icon:

// Core
import React from 'react'

// ___________________________________________________________________

const GitHub = () => (
  <svg version="1.1" width="32" viewBox="0 0 32 32">
    <path d="M16 0c-8.835 0-16 7.162-16 16 0 7.068 4.579 13.072 10.938 15.188.8.147 1.094-.357 1.094-.781 0-.38-.023-1.384-.031-2.719-4.451.967-5.375-2.156-5.375-2.156-.728-1.848-1.781-2.313-1.781-2.313-1.453-.993.125-1 .125-1 1.606.113 2.438 1.656 2.438 1.656 1.427 2.445 3.745 1.754 4.656 1.344.145-1.034.574-1.756 1.031-2.156-3.553-.404-7.313-1.774-7.313-7.906 0-1.747.632-3.163 1.656-4.281-.164-.405-.714-2.048.156-4.25 0 0 1.349-.414 4.406 1.656 1.276-.355 2.639-.524 4-.531 1.359.006 2.722.176 4 .531 3.055-2.07 4.406-1.656 4.406-1.656.873 2.203.321 3.846.156 4.25 1.025 1.119 1.656 2.535 1.656 4.281 0 6.147-3.748 7.51-7.313 7.906.575.494 1.094 1.476 1.094 2.969 0 2.139-.031 3.851-.031 4.375 0 .428.282.938 1.094.781 6.352-2.12 10.938-8.12 10.938-15.188 0-8.838-7.163-16-16-16z" />
  </svg>
)

export default GitHub
