// Facebook Icon:
// Simple Facebook icon.

// Core
import React from 'react'

// ___________________________________________________________________

const Facebook = () => (
  <svg width="32px" viewBox="0 0 32 32">
    <path d="M28.44,0H3.56A3.57,3.57,0,0,0,0,3.56V28.44A3.57,3.57,0,0,0,3.56,32H16V19.56H12.44v-4.4H16V11.51C16,7.66,18.15,5,22.7,5h3.2V9.6H23.77a2.35,2.35,0,0,0-2.44,2.55v3H25.9l-1,4.4H21.33V32h7.11A3.57,3.57,0,0,0,32,28.44V3.56A3.57,3.57,0,0,0,28.44,0Z" />
  </svg>
)

export default Facebook
