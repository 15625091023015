// LinkedIn Icon:

// Core
import React from 'react'

// ___________________________________________________________________

const LinkedIn = () => (
  <svg version="1.1" width="32" viewBox="0 0 32 32">
    <path d="M29.63 0h-27.268c-1.304 0-2.362 1.034-2.362 2.306v27.385c0 1.274 1.058 2.309 2.362 2.309h27.268c1.305 0 2.37-1.035 2.37-2.309v-27.385c0-1.272-1.065-2.306-2.37-2.306zm-20.139 27.268h-4.748v-15.271h4.748v15.271zm-2.374-17.359c-1.521 0-2.751-1.233-2.751-2.753 0-1.518 1.23-2.751 2.751-2.751 1.517 0 2.751 1.233 2.751 2.751 0 1.52-1.233 2.753-2.751 2.753zm20.15 17.359h-4.741v-7.426c0-1.772-.035-4.05-2.467-4.05-2.47 0-2.847 1.929-2.847 3.921v7.554h-4.745v-15.271h4.553v2.086h.065c.634-1.2 2.182-2.466 4.491-2.466 4.803 0 5.69 3.162 5.69 7.276v8.376z" />
  </svg>
)

export default LinkedIn
