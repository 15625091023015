// Global context

import React from 'react'

// ___________________________________________________________________

type ContextProps = {
  
}

const StoreContext = React.createContext<ContextProps>({})

export default StoreContext
